import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-scroll";
import "./AppHeader.scss";
import Logo from "../../images/250px-logo-black-png.png";

const AppHeader = () => {
  const [headerOpen, setOpen] = useState(false);
  const [headerShrinkActive, setShrinkActive] = useState(false);
  const [headerShrunk, setShrunk] = useState(false);

  const handleEscape = useCallback((event) => {
    const escapeKeyCode = 27;
    if (event.keyCode === escapeKeyCode) {
      setOpen(false);
    }
  }, []);

  const handleScroll = () => {
    const shrinkAfterPixels = 200;

    if (window.scrollY > shrinkAfterPixels) {
      setShrinkActive(true);
      setShrunk(true);
    } else {
      setShrunk(false);
    }
  };

  useEffect(() => {
    const keydown = "keydown";
    const scroll = "scroll";
    const useCapture = false;

    document.addEventListener(keydown, handleEscape, useCapture);
    document.addEventListener(scroll, handleScroll, useCapture);

    return () => {
      document.removeEventListener(keydown, handleEscape, useCapture);
      document.removeEventListener(scroll, handleScroll, useCapture);
    };
  });

  return (
    <header
      className={`header ${
        headerShrinkActive &&
        (headerShrunk ? "header-shrunk" : "header-expanded")
      } `}
    >
      <Link to="root" smooth={true}>
        <img src={Logo} alt="Cumulus Software" className="header--logo" />
      </Link>

      <svg
        className="header--navicon"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setOpen(!headerOpen)}
      >
        <path d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
      </svg>

      <nav
        className={`header--nav ${
          headerOpen ? "header--nav-open" : "header--nav-closed"
        } ${headerShrinkActive && headerShrunk && "header--nav-shrunk"}`}
      >
        <Link
          to="root"
          smooth={true}
          className="header--navitem"
          onClick={() => setOpen(false)}
        >
          Home
        </Link>
        <Link
          to="specialisms"
          smooth={true}
          offset={-30}
          className="header--navitem"
          onClick={() => setOpen(false)}
        >
          Specialisms
        </Link>
        <Link
          to="casestudies"
          smooth={true}
          offset={-30}
          className="header--navitem"
          onClick={() => setOpen(false)}
        >
          Case Studies
        </Link>
        {/* <a href="#trainingvideos" className="header--navitem">
          Training Videos
        </a> */}
        <Link
          to="contact"
          smooth={true}
          offset={-30}
          className="header--navitem header--navitem-last"
          onClick={() => setOpen(false)}
        >
          Contact
        </Link>
      </nav>
    </header>
  );
};

export default AppHeader;
