import React from "react";
import "./App.scss";
import AppHeader from "./AppHeader/AppHeader";
import Home from "./Home/Home";
import Specialisms from "./Specialisms/Specialisms";
import CaseStudies from "./CaseStudies/CaseStudies";
import Contact from "./Contact/Contact";
import AppFooter from "./AppFooter/AppFooter";
import ToTopTab from "./Common/ToTopTab/ToTopTab";

function App() {
  return (
    <React.Fragment>
      <AppHeader />
      <main>
        <Home />
        <Specialisms />
        <CaseStudies />
        <Contact />
      </main>
      <AppFooter />
      <ToTopTab />
    </React.Fragment>
  );
}

export default App;
