import React from "react";
import "./Contact.scss";
import LinkedInLogo from "../../images/LI-In-Bug.png";

const Contact = () => {
  return (
    <section className="contact">
      <h2 className="contact--title">Take the first step to support</h2>
      <p className="contact--linkedintext">Reach out on LinkedIn</p>
      <p className="contact--linkedinlogo">
        <a
          href="https://www.linkedin.com/in/robertscp/"
          title="Follow on LinkedIn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedInLogo} alt="Follow On LinkedIn" />
        </a>
      </p>
    </section>
  );
};

export default Contact;
