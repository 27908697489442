import React from "react";
import "./AppFooter.scss";

const AppFooter = () => {
  return (
    <footer className="footer">
      <p className="footer--company">
        © Cumulus Software Ltd 2020 | Company No: 10657645 | VAT No: 264970375
      </p>
      <p className="footer--location">
        Based in Downton, Wiltshire, supporting businesses across the South
        including Salisbury, Bournemouth, Poole, Eastleigh, Winchester,
        Southampton, Portsmouth, Basingstoke, Reading, Bath, Bristol and London,
        as well as further afield.
      </p>
      <address className="footer--address">
        Registered Office: C/O Clever Accounts Ltd, Brookfield Court, Selby
        Road, Garforth, Leeds LS25 1NB
      </address>
    </footer>
  );
};

export default AppFooter;
