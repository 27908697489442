import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./ToTopTab.scss";

const ToTopTab = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const handleScroll = () => {
    const displayAfterPixels = 400;
    const verticalScrollPosition = window.scrollY;

    if(verticalScrollPosition > displayAfterPixels) {
        setOpen(true);
        setActive(true);
    } else {
        setOpen(false);
    }
  };

  useEffect(() => {
    const scroll = "scroll";
    const useCapture = false;

    document.addEventListener(scroll, handleScroll, useCapture);

    return () => {
      document.removeEventListener(scroll, handleScroll, useCapture);
    };
  });

  return (
    <Link
      to="root"
      smooth={true}
      className={`totoptab ${active && (open ? "totoptab-visible" : "totoptab-hidden")}`}
      title="To top of page"
    >
      ^
    </Link>
  );
};

export default ToTopTab;
