import React, { Fragment } from "react";
import "./Home.scss";

const Home = () => {
  return (
    <Fragment>
      <section className="home">
        <h1 className="home--title">Helping businesses grow</h1>
        <ul className="home--attributes">
          <li>Professional</li>
          <li>Experienced</li>
          <li>Approachable</li>
          <li>Supportive</li>
          <li>Delivery Focused</li>
        </ul>
      </section>
      <div className="home--overlay"></div>
    </Fragment>
  );
};

export default Home;
