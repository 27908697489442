import React from "react";
import "./Specialisms.scss";
import AzureImage from "../../images/azure-250px.png";
import WebAppImage from "../../images/web-applications2.png";
import AgileImage from "../../images/coaching2.png";

const Specialisms = () => {
  return (
    <section className="specialisms">
      <h2 className="specialisms--title">Specialisms</h2>
      <p className="specialisms--description">
        We have extensive experience in developing maintainable, reliable and
        scalable web applications, along with supporting infrastructure and
        services. Specialists in leveraging Microsoft technologies and modern
        JavaScript frameworks, we focus on delivering incrementally and
        collaboratively.
      </p>

      <ul className="specialisms--list">
        <li className="specialisms--listitem">
          <div className="specialisms--itemimage">
            <img src={WebAppImage} alt="" />
          </div>
          <div className="specialisms--itemcontent">
            <h3 className="specialisms--itemtitle">Web Applications</h3>
            <p className="specialisms--itemsubtitle">
              Development and Consulting
            </p>
            <p className="specialisms--itemdescription">
              Use Microsoft technologies such as .Net Core, C#, and SQL Server,
              complimented by modern JavaScript frameworks such as ReactJs,
              database technologies such as SQL Server, CosmosDb and MongoDb,
              and testing frameworks such as Specflow and xUnit, to create rich,
              powerful, scalable and maintainable websites and applications.
            </p>
            <p className="specialisms--itemdescription">
              Drawing on over a decade of experience of successfully delivering
              websites, API's and supporting services, using a range of tools
              and frameworks, for small businesses to high-traffic international
              enterprises, helps us select the right technologies to help you
              build for success.
            </p>
          </div>
        </li>
        <li className="specialisms--listitem">
          <div className="specialisms--itemimage">
            <img src={AzureImage} alt="" />
          </div>
          <div className="specialisms--itemcontent">
            <h3 className="specialisms--itemtitle">Cloud (Azure)</h3>
            <p className="specialisms--itemsubtitle">Consulting</p>
            <p className="specialisms--itemdescription">
              Increase performance, improve flexibility and reduce costs by
              harnessing the power of Microsoft Azure.
            </p>
            <p className="specialisms--itemdescription">
              Having delivered solutions on both on-premise and cloud platforms,
              we are well placed to advise on the benefits harnessing the cloud
              can bring, and can help choose the most appropriate services to
              exploit for maximum gain.
            </p>
          </div>
        </li>
        <li className="specialisms--listitem">
          <div className="specialisms--itemimage">
            <img src={AgileImage} alt="" />
          </div>
          <div className="specialisms--itemcontent">
            <h3 className="specialisms--itemtitle">Agile and Devops</h3>
            <p className="specialisms--itemsubtitle">Coaching and Consulting</p>
            <p className="specialisms--itemdescription">
              Deliver value quickly and efficiently, respond to change, and
              outperform the market by embracing Agile and leveraging DevOps
              culture and practices.
            </p>
            <p className="specialisms--itemdescription">
              We have worked with cutting-edge, high performing companies to
              develop and adapt processes, foster collaboration and reduce
              feedback times.
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Specialisms;
