import React from "react";
import "./CaseStudies.scss";

const CaseStudies = () => {
  return (
    <section className="casestudies">
      <h2 className="casestudies--title">Case Studies</h2>

      <div className="casestudy">
        <h2 className="casestudy--title">PureGym</h2>
        <p className="casestudy--background">
          Boasting over 1m members and priding themselves as the most digitally
          enabled gym chain in the UK, PureGym have invested heavily in
          technology. Their collaborative Southampton-based development team
          leverage agile and DevOps culture and practices, and have built a
          Continuous Deployment pipeline capable of dozens of deployments of
          their ecommerce platform every week. Cumulus Software were engaged to
          provide C#/.Net, JavaScript/React and Azure development services to
          support the growth and resilience of the website.
        </p>

        <div className="casestudy--section">
          <p>
            To provide the best possible service to their members and to help
            gain a deeper understanding of the reasons for leaving, PureGym were
            looking to trial offering online cancellations.
          </p>
          <p>
            To facilitate these goals, Cumulus Software assisted with the
            architecture and development of a self-service cancellation
            solution.
          </p>
          <p>
            Cross-functional technical workshops held to gather ideas and
            formulate an approach, and the agreed architecture was a microsite
            using a ReactJs front-end built using Gatsby, calling back to .Net
            Core WebApi services which updated the membership system and stored
            cancellation information in CosmosDb. Using agile principles, the
            solution was developed and deployed incrementally behind a feature
            switch, to facilitate early delivery of value and feedback.
          </p>
          <p>
            Despite the project being cut short, the incremental approach meant
            there was a working solution completed and deployed. This proved
            sufficient for PureGym to go ahead with a successful trial, learn
            from the results and justify further investment in the solution.
          </p>
        </div>
      </div>

      <div className="casestudy">
        <h2 className="casestudy--title">Clarksons Platou</h2>
        <p className="casestudy--background">
          A FTSE 250 listed shipbroker, Clarksons Platou are headquartered in
          central London with offices around the world. A suite of bespoke
          intranet applications underpin operations by ensuring brokers and
          support services have accurate, up-to-date information at their
          fingertips. Cumulus Software were engaged to support a
          cross-functional Scrum team with C#/.Net, JavaScript/React/AngularJs
          and Azure development services.
        </p>

        <div className="casestudy--section">
          <p>
            To extend their competitive advantage, Clarksons wanted to redevelop
            and enhance an application used on the floor by brokers when
            arranging deals for clients, through improved usability and
            intergating additional information sources, and reducing costs by
            eliminating a dependency on a 3rd party tool.
          </p>
          <p>
            To facilitate this, Cumulus Software assisted with the greenfield
            development of a new application to integrate into the suite used by
            brokers.
          </p>
          <p>
            The solution was developed using Azure Functions to collate and
            process information from a variety of internal and external sources,
            and store it in Azure CosmosDb. A .Net Core Web API application
            provided endpoints to expose the information and perform
            calculations based on parameters set by the brokers. ReactJs was
            used for the front-end to provide a rich user interface.
          </p>
          <p>
            The new application was delivered, integrated with the new
            information sources and calculations now internal to the
            application, removing the 3rd party dependency.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;
